import React from 'react'
import Acordian from './Acordian';


const userQuestions = [
    {
      id: 1,
      question: "What happens when a fitness trainer does not provide a great service?",
      answer:
        "Any potential arrangement or agreement is ultimately between yourself and any prospective personal trainer, that you choose to hire. When you match to a personal trainer, you will have access to their professional information including their credentials.\nThis will help you to make an informed decision.",
    },
    {
      id: 2,
      question: "How do I cancel my subscription?",
      answer:
        "Android:\n\u2022 Open up Google Play\n\u2022 Click payment & subscriptions\n\u2022 Select subscription & click cancel\n\nIOS:\n\u2022 Open settings\n\u2022 Click your name - click subscriptions\n\u2022 Choose subscription\n\u2022 Cancel subscription",
    },
  ];
  

const UserFAQ = () => {
  return (
   <Acordian questions={userQuestions}/>
  )
}

export default UserFAQ

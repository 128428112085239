import React, { useRef } from 'react';
import Promo from '../assets/Promo.mp4';
import {motion,useInView} from 'framer-motion'
import '../css/FitnessPromo.scss';


const videoVariant ={
    initial :{
        y:500,
        opacity:0
    },
    animate:{
        y:0,
        opacity:1,
        transition:{
            duration:1,
            delay:0.2,

        }
    }
}


const FitnessPromo = () => {
    const ref = useRef();
    const isInView = useInView(ref,{margin:"-100px"})
  return (
    <motion.div className='fitness-promo' variants={videoVariant} initial="initial" animate={isInView && "animate"} ref={ref}>

      <motion.div className='video-container' >
        <video className='promo-video' src={Promo} autoPlay loop muted />
      </motion.div>
    </motion.div>
  );
};

export default FitnessPromo;

import React from 'react';
import PropTypes from 'prop-types';
import '../css/Button.css';

const Button = ({
  text = '',
  onClick = () => {},
  type = 'button',
  className = '',
  disabled = false,
  image = null
}) => {
  return (
    <button 
      type={type}
      className={`btn ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {image && <img src={image} alt={text} className='button-image'/>} 
      {text && <span>{text}</span>}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  image: PropTypes.string,
};

export default Button;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { LanguageProvider } from './service/LanguageContext';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 < LanguageProvider>
    <App />
    </LanguageProvider>
);
reportWebVitals();

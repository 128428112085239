import React from 'react'
import Terms from '../Components/Terms'
import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'
import Title from '../Components/Title'


const TermsPolicy = () => {
  return (
    <div>
        <Navigation/>
        <Title Title='Grow Your Business'/>
        <Terms/>
<Footer/>
    </div>
  )
}

export default TermsPolicy

import React, { useState } from "react";
import "../css/Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const Modal = ({ closeModal, changeLanguage, currentLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="cancelButton">
          <button onClick={() => closeModal(false)}>
          <FontAwesomeIcon icon={faTimes} />

          </button>
        </div>
        <div className="modaltitle">
          <h4>Select the Language</h4>
        </div>
        <div className="modalbody">
          <div className="languages">
<div className="countrylist">
        <p onClick={() => setSelectedLanguage("en")} className={selectedLanguage === "en" ? "selected" : ""}>English</p>
<p onClick={() => setSelectedLanguage("es")} className={selectedLanguage === "es" ? "selected" : ""}>Spanish</p>
<p onClick={() => setSelectedLanguage("fr")} className={selectedLanguage === "fr" ? "selected" : ""}>French</p>
<p onClick={() => setSelectedLanguage("de")} className={selectedLanguage === "de" ? "selected" : ""}>German</p>
<p onClick={() => setSelectedLanguage("zh-CN")} className={selectedLanguage === "zh-CN" ? "selected" : ""}>Chinese (Simplified)</p>
<p onClick={() => setSelectedLanguage("zh-TW")} className={selectedLanguage === "zh-TW" ? "selected" : ""}>Chinese (Traditional)</p>
<p onClick={() => setSelectedLanguage("ja")} className={selectedLanguage === "ja" ? "selected" : ""}>Japanese</p>
<p onClick={() => setSelectedLanguage("ko")} className={selectedLanguage === "ko" ? "selected" : ""}>Korean</p>
<p onClick={() => setSelectedLanguage("pt")} className={selectedLanguage === "pt" ? "selected" : ""}>Portuguese</p>
<p onClick={() => setSelectedLanguage("ru")} className={selectedLanguage === "ru" ? "selected" : ""}>Russian</p>
</div>




<div className="countrylist">
<p onClick={() => setSelectedLanguage("it")} className={selectedLanguage === "it" ? "selected" : ""}>Italian</p>
<p onClick={() => setSelectedLanguage("nl")} className={selectedLanguage === "nl" ? "selected" : ""}>Dutch</p>
<p onClick={() => setSelectedLanguage("ar")} className={selectedLanguage === "ar" ? "selected" : ""}>Arabic</p>
<p onClick={() => setSelectedLanguage("hi")} className={selectedLanguage === "hi" ? "selected" : ""}>Hindi</p>
<p onClick={() => setSelectedLanguage("bn")} className={selectedLanguage === "bn" ? "selected" : ""}>Bengali</p>
<p onClick={() => setSelectedLanguage("pa")} className={selectedLanguage === "pa" ? "selected" : ""}>Punjabi</p>
<p onClick={() => setSelectedLanguage("ur")} className={selectedLanguage === "ur" ? "selected" : ""}>Urdu</p>
<p onClick={() => setSelectedLanguage("tr")} className={selectedLanguage === "tr" ? "selected" : ""}>Turkish</p>
<p onClick={() => setSelectedLanguage("vi")} className={selectedLanguage === "vi" ? "selected" : ""}>Vietnamese</p>
<p onClick={() => setSelectedLanguage("th")} className={selectedLanguage === "th" ? "selected" : ""}>Thai</p>

</div>



<div className="countrylist">
<p onClick={() => setSelectedLanguage("pl")} className={selectedLanguage === "pl" ? "selected" : ""}>Polish</p>
<p onClick={() => setSelectedLanguage("el")} className={selectedLanguage === "el" ? "selected" : ""}>Greek</p>
<p onClick={() => setSelectedLanguage("he")} className={selectedLanguage === "he" ? "selected" : ""}>Hebrew</p>
<p onClick={() => setSelectedLanguage("sv")} className={selectedLanguage === "sv" ? "selected" : ""}>Swedish</p>
<p onClick={() => setSelectedLanguage("no")} className={selectedLanguage === "no" ? "selected" : ""}>Norwegian</p>
<p onClick={() => setSelectedLanguage("da")} className={selectedLanguage === "da" ? "selected" : ""}>Danish</p>
<p onClick={() => setSelectedLanguage("fi")} className={selectedLanguage === "fi" ? "selected" : ""}>Finnish</p>
<p onClick={() => setSelectedLanguage("hu")} className={selectedLanguage === "hu" ? "selected" : ""}>Hungarian</p>
<p onClick={() => setSelectedLanguage("cs")} className={selectedLanguage === "cs" ? "selected" : ""}>Czech</p>
<p onClick={() => setSelectedLanguage("ro")} className={selectedLanguage === "ro" ? "selected" : ""}>Romanian</p>
</div>

<div className="countrylist">

<p onClick={() => setSelectedLanguage("id")} className={selectedLanguage === "id" ? "selected" : ""}>Indonesian</p>
<p onClick={() => setSelectedLanguage("ms")} className={selectedLanguage === "ms" ? "selected" : ""}>Malay</p>
<p onClick={() => setSelectedLanguage("tl")} className={selectedLanguage === "tl" ? "selected" : ""}>Filipino</p>
<p onClick={() => setSelectedLanguage("sw")} className={selectedLanguage === "sw" ? "selected" : ""}>Swahili</p>
<p onClick={() => setSelectedLanguage("ta")} className={selectedLanguage === "ta" ? "selected" : ""}>Tamil</p>
<p onClick={() => setSelectedLanguage("te")} className={selectedLanguage === "te" ? "selected" : ""}>Telugu</p>
<p onClick={() => setSelectedLanguage("mr")} className={selectedLanguage === "mr" ? "selected" : ""}>Marathi</p>
<p onClick={() => setSelectedLanguage("gu")} className={selectedLanguage === "gu" ? "selected" : ""}>Gujarati</p>
<p onClick={() => setSelectedLanguage("kn")} className={selectedLanguage === "kn" ? "selected" : ""}>Kannada</p>
<p onClick={() => setSelectedLanguage("ml")} className={selectedLanguage === "ml" ? "selected" : ""}>Malayalam</p>
</div>

<div className="countrylist">

  <p onClick={() => setSelectedLanguage("si")} className={selectedLanguage === "si" ? "selected" : ""}>Sinhalese</p>
<p onClick={() => setSelectedLanguage("sk")} className={selectedLanguage === "sk" ? "selected" : ""}>Slovak</p>
<p onClick={() => setSelectedLanguage("hr")} className={selectedLanguage === "hr" ? "selected" : ""}>Croatian</p>
<p onClick={() => setSelectedLanguage("bg")} className={selectedLanguage === "bg" ? "selected" : ""}>Bulgarian</p>
<p onClick={() => setSelectedLanguage("sr")} className={selectedLanguage === "sr" ? "selected" : ""}>Serbian</p>
<p onClick={() => setSelectedLanguage("lt")} className={selectedLanguage === "lt" ? "selected" : ""}>Lithuanian</p>
<p onClick={() => setSelectedLanguage("lv")} className={selectedLanguage === "lv" ? "selected" : ""}>Latvian</p>
<p onClick={() => setSelectedLanguage("et")} className={selectedLanguage === "et" ? "selected" : ""}>Estonian</p>
<p onClick={() => setSelectedLanguage("sl")} className={selectedLanguage === "sl" ? "selected" : ""}>Slovenian</p>
<p onClick={() => setSelectedLanguage("is")} className={selectedLanguage === "is" ? "selected" : ""}>Icelandic</p>

</div>
</div>
        </div>
        <div className="modalfooter">
          <button
            style={{ backgroundColor: "red", color: "white" }}
            onClick={() => closeModal(false)}
          >
            Cancel
          </button>
          <button
            style={{ backgroundColor: "#192965", color: "white" }}
            onClick={() => {
              changeLanguage(selectedLanguage);
              closeModal(false);
            }}
          >
            {" "}
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React from 'react'
import Acordian from './Acordian';

const trainerQuestions = [
    {
      id: 1,
      question: "How does Match Your Fit work?",
      answer:
        "Match your fit is a lead generation platform. Where it connects a customer seeking a fitness trainer or class ",
    },
    {
      id: 2,
      question: "What type of fitness trainers are on Match Your Fit?",
      answer:
        "We've every type of fitness trainer imaginable. Such as, CrossFit trainers, strength & conditioning, Olympic lifting & much much more.",
    },
    {
      id: 3,
      question: "How to make a great profile?",
      answer:
        "It's very important to catch the attention of the potential customer. Give as much accurate information as possible.\n\u2022 Make sure all contact details are correct. \n\u2022  A well written description about your business and experience. \n\u2022 Display high quality images & videos ",
    },
    {
      id: 4,
      question: "How to respond to job interests?",
      answer:
        "Respond as quick as possible to matches or super interests . Usually a personal trainer has more chance securing the customer with a swift response. \n\u2022 ° Customers have emails & phone numbers. We would highly recommend call or email for the first interaction.",
    },
    {
      id: 5,
      question: "How do matches work?",
      answer:
        "Users can swipe right & match with your profile",
    },
    {
      id: 6,
      question: "What's a super fitness match?",
      answer:
        "It's when a customer shows a potential customer is very interested in your services.",
    },
    {
      id: 7,
      question: "Does Match Your Fit cost me anything?",
      answer:
        "To match & message customers we have monthly subscription",
    },
  ];
  



const TrainerFAQ = () => {
  return (
    <Acordian questions={trainerQuestions}/>
  )
}

export default TrainerFAQ

import axios from 'axios';

const API_KEY = 'AIzaSyCj0HTjj6Ki2z1vHy-EsFNgj-BqNcf46Ao';


export const translateText = async (text, targetLang) => {
    const url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
  
    try {
      const response = await axios.post(url, {
        q: text,
        target: targetLang,
        format: 'text',
      });
  
      if (response.data && response.data.data && response.data.data.translations) {
        return { translatedText: response.data.data.translations[0].translatedText };
      } else {
        return { error: 'Invalid response format' };
      }
    } catch (error) {
      console.error('Error translating text:', error.response ? error.response.data : error.message);
      return { error: error.response ? error.response.data.error.message : error.message };
    }
  };
import React from 'react'
import MainImage from '../assets/pexels-olly-3772502.jpg'
import '../css/MainScreen.scss'
import {motion} from 'framer-motion'
import useTranslation from '../Hooks/useTranslation'


const textVariant = {
    initial :{
        x:-500,
        opacity:0,
    },
    animate:{
        x:0,
        opacity:1,
        transition:{
            duration:1,         
            staggerChildren:0.1,

        }
    }
}
const slideVariants = {
    initial:{
        x:0,
    },
    animate:{
        x:"220%",
        transition:{
            repeat:Infinity,
            repeatType:"mirror",
            duration:3
        },
    }
}

const MainScreen = () => {
    const welcome = useTranslation('Welcome to Fitness Pro');
    const welcomeDescription = useTranslation('Your journey to a healthier life starts here. Join us for personalized training and guidance');
    const welcomeButton = useTranslation('Join Now');

  return (
    <div className='main'>
        <div className='wrapper'>
        <motion.div className='textContainer' variants={textVariant} initial="initial" animate="animate">
            <motion.h2 variants={textVariant}>{welcome}</motion.h2>
            <motion.h4 variants = {textVariant}> {welcomeDescription}
</motion.h4>
<motion.div className='buttons' variants={textVariant}>
    <button variants ={textVariant}>{welcomeButton}</button>

</motion.div>

        </motion.div>
        </div>
        <motion.div className='slidingText' variants={slideVariants} 
       initial="initial" animate="animate" >
            Match Your Fit

        </motion.div>
        <div className='ImageMain'>

            {/* <img src={MainImage} alt=''/> */}

        </div>
      
    </div>
  )
}

export default MainScreen

import React from "react";
import Sidebar from "./Sidebar";
import MainScreen from "./MainScreen";
import Logo from "../assets/Matchyourfit.png";
import "../css/Navigation.scss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import LanguageSelector from "./LanguageSelector";

const Navigation = () => {
  return (
    <div className="navigation">
      <Sidebar />
      <div className="wrapper">
        <div className="logoimage">
          <Link to={"/"}>
            <motion.img
              src={Logo}
              alt=""
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1.5 }}
              transition={{ duration: 0.5 }}
              className="fitneslogo"
            />
          </Link>
        </div>
       
      </div>
      <div className="selector">
          <LanguageSelector/>
        </div>
    </div>
  );
};

export default Navigation;

// src/Components/LanguageSelector.js
import React from 'react';
import { useLanguage } from '../service/LanguageContext';
import {useState} from 'react'
import Modal from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import '../css/Languagebutton.css'

const LanguageSelector = () => {
  const { language, changeLanguage } = useLanguage();
  const [open,setOpen] =useState(false)

  return (
    <div>
      {/* <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
        <option value="de">German</option>
      </select> */}

      <button className='languagebutton' onClick={()=>setOpen(true)}>
      <FontAwesomeIcon icon={faGlobe} />
        Language</button>
        {open && <Modal closeModal ={setOpen} changeLanguage={changeLanguage} currentLanguage = {language}/>}
    </div>
  );
};

export default LanguageSelector;

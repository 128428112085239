// src/pages/Home.js
import React from 'react';
import Navigation from '../Components/Navigation';
import MainScreen from '../Components/MainScreen';
import Title from '../Components/Title';
import Plans from '../Components/Plans';
import PostAJob from '../Components/PostAJob';
import LifeCycle from '../Components/LifeCycle';
import FindTrainer from '../Components/FindTrainer';
import FitnessPromo from '../Components/FitnessPromo';
import Locations from '../Components/Locations';
import Footer from '../Components/Footer';
import LanguageSelector from '../Components/LanguageSelector';
import useTranslation from '../Hooks/useTranslation';

const Home = () => {
  const personalizedWorkouts = useTranslation('Personalized Workouts, Nutrition Plans, and Progress Tracking to Help You Succeed');
  const achieveFitnessGoals = useTranslation('Achieve Your Fitness Goals with Tailored Plans');
  const revolutionizeFitnessJourney = useTranslation('Revolutionize Your Fitness Journey');
  const transformFitnessJourney = useTranslation('Transform Your Fitness Journey');
  const exploreFitnessDestinations = useTranslation('Explore Premier Fitness Destinations');

  return (
    <div>
      <Navigation />
      <MainScreen />
      {/* <LanguageSelector /> */}
      <Title Subtitle={personalizedWorkouts} Title={achieveFitnessGoals} />
      <Plans />
      <PostAJob />
      <Title Title={revolutionizeFitnessJourney} />
      <LifeCycle />
      <FindTrainer />
      <Title Title={transformFitnessJourney} />
      <FitnessPromo />
      <Title Title={exploreFitnessDestinations} />
      {/* <Locations /> */}
      <Footer />
    </div>
  );
};

export default Home;

import React from 'react'
import JobPost from '../assets/Postjob.jpg'
import '../css/PostJob.css'
import Button from './Button';
import FitnessApple from '../assets/Apple.png'
import FitnessAndroid from '../assets/playstore.png'
import { motion } from 'framer-motion';
import useTranslation from '../Hooks/useTranslation'



const container = {
  hidden:{
    opacity:1
  },
  visible:{
    transition:{
    staggerChildren:0.1,
    },
  },
};

const Child ={
  hidden:{
    opacity:0,
    y:-100
  },
visible:{
  opacity:1,
  y:0,
  transition:{
    type:"string",
    damping:10,
    stiffness:100,
    duration:0.3
  },
},
};

const PostAJob = () => {
const translatedtitleWords =useTranslation('Post A Job').split(" ");
const transledDescription = useTranslation('Tell us what type of Personal Trainer you require. Which area you need a personal trainer or perhaps online. How long you need the trainer for.').split(" ");
const translatedCallToAction = useTranslation('Get Started Today').split(" ");


  return (
    <div className='post-job'> 
    <div className='job-image'>
    <img src={JobPost} alt=''/>
    </div>
    <motion.div className='post-job-text' variants={container} initial="hidden" whileInView ="visible" viewport={{once:true}}>
        <motion.h2 variants={container} initial="hidden" whileInView="visible" viewport={{once:true}}>{translatedtitleWords.map((word,index)=>(
            <motion.span variants={Child} key={index} >{word}&nbsp;</motion.span>
        ))}</motion.h2>
        <motion.p  variants={container} initial="hidden" whileInView="visible" viewport={{once:true}}>{transledDescription.map((word,index)=>(
            <motion.span variants={Child} key={index}>{word}&nbsp;</motion.span>
        ))}</motion.p>
        <motion.p variants={container} initial="hidden" whileInView="visible" viewport={{once:true}}>{translatedCallToAction.map((word,index)=>(
            <motion.span variants={Child} key={index}>{word} &nbsp;</motion.span>
        ))}</motion.p>
        <motion.div className='job-buttons' variants={container} initial="hidden" whileInView="visible" viewport={{once:true}}>
          <motion.div variants={Child}>
          <Button image={FitnessAndroid}  alt=""/>

          </motion.div>
          <motion.div variants={Child}>
          <Button image={FitnessApple} alt=""/>

          </motion.div>
        </motion.div>
    </motion.div>
      
    </div>
  )
}

export default PostAJob

import React from 'react'
import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'
import WorkSection from '../Components/WorkSection'
import Title from '../Components/Title'
import useTranslation from '../Hooks/useTranslation'

const HowItWorks = () => {
const getFound = useTranslation("Get Found");
const getFoundDescription = useTranslation("Build your personal training service or business. With so many new eyes seeing your business profile on ‘Match Your Fit,’ you can increase your visibility and attract more clients.");
const growBusiness = useTranslation("Grow Your Business");
const growBusinessDescription = useTranslation("Take your business to the next level by growing it exponentially on Match Your Fit. Let’s take the stress out of marketing campaigns. We provide you with instant access to customers who are actively seeking personal trainers. Build your business with Match Your Fit and never look back. Let’s do it together.")
const recruitment = useTranslation("Match Your Fit Recruitment");
const recruitmentDescription = useTranslation("Need more scale? Get connected to our trainer community today with our unique recruitment packages. Stay in control of your recruitment requirements with Match Your Fit. We have packages to suit every type of recruiting solution necessary to sustain and grow your business needs.");
const postJob = useTranslation("Post A Job");
const postJobDescription = useTranslation("Tell us what type of personal trainer you need, whether it's for in-person sessions or online coaching ");
const requirements = useTranslation("Specify Your Requirements");
const requirementsDescription = useTranslation("Which area you need a personal trainer in or if you prefer online sessions. How long you need the trainer for and what specific goals you have.")
const sitBack =useTranslation( "Sit Back & Relax")
const sitBackDescription = useTranslation("While we search through thousands of trainers to find the best match for your needs. Check out the trainers' profiles and reviews to ensure they meet your standards. Then choose your favorite trainer that suits all your fitness goals.")
  return (
    <div>
      <Navigation/>
      <Title Title='For Personal Trainers'/>
      
      <WorkSection 
        Number='01' 
        Title={getFound} 
        Subtitle={getFoundDescription}
        isReversed={false}
      />
      <WorkSection 
        Number='02' 
        Title={growBusiness} 
        Subtitle={growBusinessDescription}
        isReversed={true}
      />
      <WorkSection 
        Number='03' 
        Title={recruitment}
        Subtitle={recruitmentDescription}
        isReversed={false}
      />
            <Title  Title='Find A Trainer'/>
            <WorkSection 
        Number='01' 
        Title={postJob}
        Subtitle={postJobDescription}
        isReversed={true}
      />
 <WorkSection 
        Number='02' 
        Title={requirements}
        Subtitle={requirementsDescription}
        isReversed={true}
      />
       <WorkSection 
        Number='03' 
        Title={sitBack}
        Subtitle={sitBackDescription}
        isReversed={true}
      />



      <Footer/>

    </div>
  )
}

export default HowItWorks

import React from "react";
import "../css/workSection.css";
import { motion } from "framer-motion";

const container = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 1.2,
      duration: 2,
    },
  },
};

const child = {
  initial: {
    opacity: 0,
    x: -500,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition:{
        duration:1.5
    }
  },
};

const WorkSection = ({ Number, Title, Subtitle, isReversed }) => {
  return (
    <motion.div
      className={`workSection ${isReversed ? "reversed" : ""}`}
      variants={container}
      initial="initial"
      whileInView="animate" viewport={{once:"true"}}
    >
      {isReversed ? (
        <>
          <motion.div className="number">
            <span>{Number}</span>
          </motion.div>
          <motion.div className="workText" variants={child}>
            <h4>{Title}</h4>
            <p>{Subtitle}</p>
          </motion.div>
        </>
      ) : (
        <>
          <motion.div className="workText" variants={child}>
            <h4>{Title}</h4>
            <p>{Subtitle}</p>
          </motion.div>
          <div className="number">
            <span>{Number}</span>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default WorkSection;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import TermsPolicy from './pages/TermsPolicy';
import { LanguageProvider } from './service/LanguageContext';
import FAQ from './pages/FAQ';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/How-it-works" element={<HowItWorks />} />
          <Route path="/terms-policy" element={<TermsPolicy />} />
          <Route path='/frequently-asked-question' element={<FAQ/>}/>
        </Routes>
      </Router>
    </LanguageProvider>
  );
}

export default App;

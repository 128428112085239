import React from "react";
import "../css/Terms.css";
import { motion } from "framer-motion";
import useTranslation from "../Hooks/useTranslation";

const container = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 1.5,
      staggerChildren: 1,
    },
  },
};

const Child = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

const Terms = () => {
  const translatedPrivacyPolicy = useTranslation("PRIVACY POLICY");
  const translatedCustomerPrivacySecurity = useTranslation("Customer Privacy & Security");
  const translatedPrivacyContent = useTranslation("At ' Match Your Fit ' we are committed to protecting the privacy and security of our customers. Our Privacy Policy sets out our privacy promise to you. We do not share your data with third parties for them to market their products/services to you.");
  const translatedIdentity = useTranslation("Identity – first name, surname, username & password. Country of residence.");
  const translatedContact = useTranslation("Contact – email address, telephone numbers and address");
  const translatedFinancial = useTranslation("Financial – payment card details, billing address, purchase information, payment history");
  const translatedDashboard = useTranslation("Dashboard – your preferences for marketing, other website preferences and feedback on your dashboard.");
  const translatedSocial = useTranslation("Social – if you decide to use any of your social media account’s to open a Nifty Driver account. Contact information will populated from those requests.");
  const translatedCookies = useTranslation("Cookies");
  const translatedCookiesContent = useTranslation("As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies.");
  const translatedThirdParties = useTranslation("Third parties or publicly available sources. We will receive personal data about you from various third parties as set out below:");
  const translatedAnalyticsProviders = useTranslation("(a) analytics providers such as Google based outside the EU;");
  const translatedAdvertisingNetworks = useTranslation("(b) advertising networks based inside OR outside EU;");
  const translatedSearchProviders = useTranslation("(c) search information providers based inside OR outside the EU.");
  const translatedContactFinancial = useTranslation("Contact, Financial and Transaction Data from providers of technical, payment and delivery services based inside OR outside the EU.");
  const translatedIdentityContactData = useTranslation("Identity and Contact Data from data brokers or aggregators based inside OR outside the EU.");
  const translatedCookiesOptOut = useTranslation("Cookies Opt-Out");
  const translatedOptOutContent = useTranslation("Some people find the idea of a website storing information on their device a little intrusive, in particular when the information is stored and used by a third party. For example, you may object to being served advertising which is targeted to your interests based on your browsing history.");
  const translatedOptOutSteps = useTranslation("If you would prefer to opt out of cookies, it is possible to control cookies by following the steps below, however you should be aware that you might lose some features and functionality of the website if you do so.");
  const translatedDeleteCookies = useTranslation("Cookies, including those which have already been set, can be deleted from your hard drive. You can also change the preferences/settings in your web browser to control cookies. In some cases, you can choose to accept cookies from the primary site, but block them from third parties. In others, you can block cookies from specific advertisers, or clear out all cookies. Deleting or blocking cookies may reduce functionality of the site.");
  const translatedOverview = useTranslation("Overview");
  const translatedOverviewContent = useTranslation("We reserve the right to change this Privacy Notice from time to time at our sole discretion. If we make any changes, we will post those changes here and update the “Last Updated” date at the bottom of this Privacy Notice. However, if we make material changes to this Privacy Notice we will bring this to the attention of the users of the services and sites.");

  return (
    <motion.div
      className="terms"
      variants={container}
      initial="initial"
      whileInView="animate"
      viewport={{ once: "true" }}
    >
      <motion.div className="privacy-content" variants={Child}>
        <h4>{translatedCustomerPrivacySecurity}</h4>
        <p>{translatedPrivacyContent}</p>
        <ul>
          <li>{translatedIdentity}</li>
          <li>{translatedContact}</li>
          <li>{translatedFinancial}</li>
          <li>{translatedDashboard}</li>
          <li>{translatedSocial}</li>
        </ul>
      </motion.div>
      <motion.div className="privacy-content" variants={Child}>
        <h4>{translatedCookies}</h4>
        <p>{translatedCookiesContent}</p>
        <p>{translatedThirdParties}</p>
        <ul>
          <li>{translatedAnalyticsProviders}</li>
          <li>{translatedAdvertisingNetworks}</li>
          <li>{translatedSearchProviders}</li>
          <li>{translatedContactFinancial}</li>
          <li>{translatedIdentityContactData}</li>
        </ul>
      </motion.div>
      <motion.div className="privacy-content" variants={Child}>
        <h4>{translatedCookiesOptOut}</h4>
        <p>{translatedOptOutContent}</p>
        <p>{translatedOptOutSteps}</p>
        <p>{translatedDeleteCookies}</p>
      </motion.div>
      <motion.div className="privacy-content" variants={Child}>
        <h4>{translatedOverview}</h4>
        <p>{translatedOverviewContent}</p>
      </motion.div>
    </motion.div>
  );
};

export default Terms;

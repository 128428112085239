import React, { useRef } from 'react';
import Trainer from '../assets/fitness.jpg';
import FitnessApple from '../assets/Apple.png'
import FitnessAndroid from '../assets/playstore.png'

import '../css/FindTrainer.css';
import { motion ,useInView} from 'framer-motion';
import Button from './Button';
import useTranslation from '../Hooks/useTranslation';



const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const Child = {
  hidden: { opacity: 0, y: -100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 10,
      stiffness: 100,
      duration:0.3
    },
  },
}; 

const FindTrainer = () => {

const translatedwords =useTranslation("Find Your Ideal Trainer").split(" ");
const sentence =useTranslation("Discover certified personal trainers who can help you reach your fitness goals. Use our mobile app for the best experience.").split("");
const callToAction = useTranslation("Download our app to get started:").split(" ");
  const ref = useRef();
  const isInView = useInView(ref,{margin:"-100px"})
  return (
    <div className='find-trainer' ref={ref} >
      <div className='trainer-image'>
        <img src={Trainer} alt='Fitness Training'  />

      </div>
      <div className='find-trainer-text'>
      <motion.h2 variants={container} initial="hidden" 
      whileInView="visible"
      viewport={{once:true}} > {translatedwords.map((word,index)=>(
        <motion.span key={index} variants={Child} >{word}&nbsp;</motion.span>
      ))}</motion.h2>

<motion.p
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {sentence.map((word, index) => (
            <motion.span key={index} variants={Child}>
              {word}&nbsp;
            </motion.span>
          ))}
        </motion.p>
      <motion.p variants={container} initial="hidden" whileInView="visible" viewport={{once:true}}>{callToAction.map((word,index)=>(
        <motion.span key={index} variants={Child}>{word}&nbsp;</motion.span>
      ))}</motion.p>
      <br/>
      <motion.div className='trainer-buttons' variants={container} initial="hidden" whileInView="visible" viewport={{once:true}}>
        <motion.div variants={Child}>
      <Button   image={FitnessApple}  className="play-app" ></Button>
      </motion.div>
      <motion.div variants={Child}>
      <Button  image={FitnessAndroid}  className="play-app"></Button>
      </motion.div>
      </motion.div>

       

      </div>
    </div>
  );
};

export default FindTrainer;
import React, { useRef } from 'react'
import workOutPlan from '../assets/WorkOutplan.jpg'
import Nutrition from '../assets/Nutrition.jpg'
import Tracking from '../assets/Tracking.jpg'
import {motion,useInView} from 'framer-motion'

import '../css/Plans.css'
import useTranslation from '../Hooks/useTranslation'


const variants ={
    initial :{
    x:-500,
    opacity:0,
    y:100
    },
    animate:{
        x:0,
        opacity:1,
        y:0,
        transition:{
            duration:1,
            staggerChildren:0.2,
        }

    }
}

const Plans = () => {

    const ref = useRef()
const isInView = useInView(ref,{margin:"-100px"})


const personalizedWorkouts = useTranslation('Personalized Workouts');
const personalizedWorkoutDescription = useTranslation('Get workout plans tailored to your fitness level and goals.');
const nutritionPlans = useTranslation('Nutrition Plans');
const nutritionPlansDescription = useTranslation('Receive customized meal plans to complement your fitness routine.')
const progressTracking = useTranslation('Progress Tracking');
const progressTrackingDescription = useTranslation('Track your progress with detailed analytics and reports.')
  return (
    <motion.div className='plans containerclass' variants={variants} initial="initial" animate={isInView && "animate" } ref={ref} >
        <motion.div className='plan ' variants={variants} >
            <img  src={workOutPlan} alt=''/>
            <div className='caption'>
                <h5>{personalizedWorkouts}</h5>
                <p>{personalizedWorkoutDescription}</p>

            </div>

        </motion.div>
        <motion.div className='plan' variants={variants}>
            <img  src={Nutrition} alt=''/>
            <div className='caption'>
                <h5>{nutritionPlans} </h5>
                <p>{nutritionPlansDescription}</p>

            </div>

        </motion.div>
        <motion.div className='plan' variants={variants}>
            <img  src={Tracking} alt=''/>
            <div className='caption'>
                <h5>{progressTracking}</h5>
                <p>{progressTrackingDescription}</p>

            </div>

        </motion.div>

      
    </motion.div>
  )
}

export default Plans

import React from 'react'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';


const Links = () => {
    const variants = {
        open:{
            transition:{
                staggerChildren:0.1,
            },
        },
        closed :{
            transition:{
                staggerChildren:0.05,
                staggerDirection:-1,
            },
        },
    };
    const itemVariants = {
        open:{
            y:0,
            opacity:1

        },
        closed:{
            y:50,
            opacity:0,
        },

    };
    const items = [ 
       { name: "Products", path:"/products"},
        { name:"Subscription",path:"/subscription"},
        {name:"About Us",path:"/about-us"},
        {name:"Safety",path:"/safety"},
        {name:"Pricing",path:"/pricing"},
        {name:"Contact Us",path:"/contact-us"},
        {name:"User Account",path:"/user-account"}
    ];

  return (
    <motion.div  style={{color:"white"}} className='links' variants={variants}>
        {items.map(item=>(
            <motion.div  key={item.name} variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>
                <Link to = {item.path} style={{color:"white",textDecoration:"none"}}>{item.name}</Link>
            </motion.div>
        ))}

    </motion.div>
  )
       
}

export default Links

import React from 'react'
import Acordian from '../Components/Acordian'
import Navigation from '../Components/Navigation'
import Footer from '../Components/Footer'
import Title from '../Components/Title'
import TrainerFAQ from '../Components/TrainerFAQ'
import UserFAQ from '../Components/UserFAQ'

const FAQ = () => {
  return (
    <div>
        <Navigation/>
        <Title Title='Frequently Asked Questions' Subtitle="If you're looking to understand how Match Your Fit can transform your fitness journey, you're in the right place. Here's a quick overview to get you acquainted with our platform and services."/>
        <Title Title='Fitness Trainers'/>
        <TrainerFAQ/>
        <Title Title='Fitness Seekers'/>
        <UserFAQ/>

      <Footer/>
    </div>
  )
}

export default FAQ

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import "../css/Acordian.css";
import { motion, AnimatePresence } from "framer-motion";



const Container = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 1,
      },
    },
  };
  
const Acordian = ({questions}) => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  
  return (
    <div className="aodian" >
        
      <motion.div className="arordian_container" variants={Container} initial='initial' animate='animate'>
      
        {questions.map((q) => (
          <div style={{ marginBottom: "4px" }} key={q.id}>
            <button
              className="acordianButton"
              onClick={() =>
                setActiveQuestion(activeQuestion === q.id ? null : q.id)
              }
            >
              {q.question}
              <span style={{ marginLeft: "10px" }}>
                {activeQuestion === q.id ? (
                  <FontAwesomeIcon icon={faMinusCircle} />
                ) : (
                  <FontAwesomeIcon icon={faPlusCircle} />
                )}
              </span>
            </button>
            <AnimatePresence>
              {activeQuestion === q.id && (
                <motion.div className="acordianAnswers"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto",transition:{duration:0.2} }}
                  exit={{ opacity: 0, height: 0 }}
                  style={{ marginTop: "4px" }}
                >
                  {q.answer.split('\n').map((line,index)=>(
                <p key={index}>{line}</p>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Acordian;

import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from '../Hooks/useTranslation';
import '../css/Title.css';

const Title = ({ Subtitle, Title }) => {
  const translatedTitle = useTranslation(Title);
  const translatedSubtitle = useTranslation(Subtitle || ''); // Handle undefined Subtitle

  return (
    <div className='title'>
      <h2>{translatedTitle}</h2>
      <br />
      {Subtitle && <p>{translatedSubtitle}</p>}
    </div>
  );
};

Title.propTypes = {
  Subtitle: PropTypes.string,
  Title: PropTypes.string.isRequired,
};

export default Title;

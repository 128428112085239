import React, { useRef } from 'react'
import Lottie from 'lottie-react'
import FirstAnimation from '../assets/firstanimation.json'
import SecondAnimation from '../assets/secondanimation.json'
import ThirdAnimation from '../assets/thirdanimation.json'
import '../css/LifeCycle.css'
import {motion, useInView} from 'framer-motion'
import useTranslation from '../Hooks/useTranslation'

const variants ={
    initial:{
        opacity:0
    },
    animate:{
        opacity:1,
        transition:{
            duration:1,
            staggerChildren:0.4,

        }
    }
}
const animateVariants = {
    initial:{
        opacity:0
    },
    animate:{
        opacity:1
    }

}


const LifeCycle = () => {
    const findYourPerfect = useTranslation('Find Your Perfect Fit');
    const findYourPerfectDescription = useTranslation('Connect with certified personal trainers tailored to your fitness needs, whether in-person or online. Post a job and let the instructors come to you for free!');
    const realReviews = useTranslation('Real Reviews, Real Results');
    const realReviewsDescription = useTranslation('Trust our robust rating and review system to ensure you receive top-quality service while trainers enjoy consistent business.');
    const secureYour  = useTranslation('Secure Your Fitness Journey');
    const secureYourDescription = useTranslation('With our secure payment methods and stringent security measures, your satisfaction is guaranteed. Train confidently and reach your goals with ease.')
    const ref= useRef()
    const isInView = useInView(ref,{margin:"-300px"})

  return (
    <motion.div className='lifecycle ' variants={variants} initial="initial" animate={isInView && "animate"} ref={ref}>
        <motion.div className='lottie-animation' variants={animateVariants}>
            <div className='card-header'>
                <Lottie animationData={SecondAnimation}/>

            </div>
            <div className='card-body'>
                <h3>{findYourPerfect}</h3>
                <p>{findYourPerfectDescription}</p>
            </div>

        </motion.div>
        <motion.div className='lottie-animation' variants={animateVariants}>
            <div className='card-header'>
            <Lottie animationData={FirstAnimation}/>


            </div>
            <div className='card-body'>
                <h3>{realReviews}</h3>
                <p>{realReviewsDescription}</p>
                
            </div>

        </motion.div>
        <motion.div className='lottie-animation' variants={animateVariants}>
            <div className='card-header'>
                <Lottie animationData={ThirdAnimation}/>

            </div>
            <div className='card-body'>
                <h3>{secureYour}</h3>
                <p>{secureYourDescription}</p>
                
            </div>

        </motion.div>



      
    </motion.div>
  )
}

export default LifeCycle

import React from "react";
import footerlogo from "../assets/Matchyourfit.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "../css/Footer.css";
import appleImage from '../assets/Apple.png'
import playstoreImage from '../assets/playstore.png'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-section">
        <div className="left-section">
            <div className="footerlogo">
              <img src={footerlogo} alt="" />
              </div>
           
              <div className="social-media">
                <li>
                  <FontAwesomeIcon icon={faFacebookF} />
                </li>
                <li>
                  <FontAwesomeIcon icon={faTwitter} />
                </li>
                <li>
                  <FontAwesomeIcon icon={faInstagram} />
                </li>
                <li>
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </li>
                <li>
                  <FontAwesomeIcon icon={faYoutube} />
                </li>
              </div>
         
          
        </div>
        <div className="center-section">
          <div>
            <h4>About Us</h4>

            <ul>
              <li>Our Story</li>
              <li>Mission & Vision</li>
              <li>Team</li>
              <li>Careers</li>
              <li>Press</li>
            </ul>
          </div>
          <div>
            <h4>Services</h4>
            <ul>
              <li>Personal Training</li>
              <li>Online Sessions</li>
              <li>In-Person Sessions</li>
              <li>Nutrition Plans</li>
              <li>Wellness Coaching</li>
            </ul>
          </div>
          <div>
            <h4>Resources</h4>
            <ul>
              <li>Fitness Tips</li>
              <li>Workout Videos</li>
              <li>Success Stories</li>
              <li>E-books</li>
              <li>Community Forum</li>
            </ul>
          </div>
        </div>
        <div className="right-section">
            <div>
                <h4>MATCH YOUR FIT</h4>
                <div className="play-store">
                <div className='footer-buttons'>
<button className='footer-app'>
    <img className='apple-store' src={appleImage} alt=''/></button>
<button className='play-app'><img className='play-store' src={playstoreImage} alt=''/></button>

</div>
                    
                </div>
            </div>
            <div>
            <h4>Support</h4>
          <ul>
            <li>Help Center</li>
            <li > <a href="/frequently-asked-question">FAQ</a></li>
            <li>Contact Us</li>
            <li><a href="/terms-policy">Privacy Policy</a></li>
            <li>Terms of Service</li>
          </ul>
            </div>

        </div>
       
      </div>
      <div className="footer-bottom">
            <span>&copy; 2024 Your Fitness App. All rights reserved.</span>
        </div>
    </div>
  );
};

export default Footer;

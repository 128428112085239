// src/Hooks/useTranslation.js
import { useEffect, useState } from 'react';
import { useLanguage } from '../service/LanguageContext';
import { translateText } from '../service/GoogleTranslate';

const useTranslation = (text) => {
    const { language } = useLanguage();
    const [translatedText, setTranslatedText] = useState(text);
    const [error, setError] = useState('');
  
    useEffect(() => {
      const fetchTranslation = async () => {
        const result = await translateText(text, language);
  
        if (result.error) {
          setError(result.error);
          setTranslatedText(text); // Fallback to original text
        } else {
          setTranslatedText(result.translatedText);
        }
      };
  
      fetchTranslation();
    }, [text, language]);
  
    if (error) {
      return `${text} (translation error: ${error})`;
    }
  
    return translatedText;
  };
  
  export default useTranslation;